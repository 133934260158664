<template>
  <div>
    <v-card class="ma-4">
      <v-toolbar>
        <v-toolbar-title>GreatScots Photo Uploader</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>This is where workers from the MarCom office can upload replacement photos for students or employees. The form has 2 fields, a name search and an uploader. Then when they press the button it will upload the photo to GreatScots, and will lock the record so the uploading script will no longer update that photo. A log of the uploaded photos will be shown at the bottom of the page so they will know which ones they have done.</p>
        <directory-search v-model="directoryId" label="Directory Search" outlined />
        <v-file-input v-model="photo" label="Photo" accept="image/jpeg" outlined />
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="isUploading" disabled>Uploading Photo...</v-btn>
        <v-btn v-else :disabled="directoryId == null || directoryId === '' || photo == null" color="primary" @click="doUpload">Upload Photo</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="log.length > 0" class="ma-4">
      <v-card-title>Upload Log</v-card-title>
      <v-card-text>
        <v-alert v-for="{ timestamp, status, text } of log" :key="timestamp" :type="status">{{ text }}</v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const directoryId = ref(null)
    const photo = ref(null)
    const isUploading = ref(false)

    const log = ref([])

    async function doUpload () {
      let formData = new FormData()
      formData.append('directoryId', directoryId.value.value)
      formData.append('file', photo.value)
      formData.append('contentType', photo.value.type)

      const name = directoryId.value.text

      try {
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        let token = window.localStorage['feathers-jwt']
        await axios.post(basePath + '/directory/photo', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } })
        log.value.splice(0, 0, { timestamp: new Date().getTime(), status: 'success', text: 'Photo updated for ' + name })
        photo.value = null
        directoryId.value = null
      } catch (e) {
        log.value.splice(0, 0, { timestamp: new Date().getTime(), status: 'success', text: 'Error updating photo for ' + name, subtext: e })
      }
      isUploading.value = false
    }

    return {
      user,
      directoryId,
      photo,
      isUploading,
      log,
      doUpload
    }
  }
}
</script>
